import httpClient from '@/service/httpClient';
import * as configuration from '@/service/http/httpRequestConfig';
import DeviceService from '@/services/device';
import dateHelper from '../../../../shared/Helpers/dateHelper';
import { useStore } from 'vuex';
import { ref } from 'vue';

export function useDeviceSettings(toast) {
  const store = useStore();
  const { organization } = store.getters;

  const devicesSettingsDict = ref({});
  const devicesStatusDict = ref({});
  // Modal Variables
  const showSchedule = ref(false);
  const deviceToSchedule = ref();
  const startEndTime = ref([]);
  const selectedDays = ref([]);
  const isScheduling = ref(false);
  const showStreamModal = ref(false);
  const streamSignal = ref(false);
  const rtcServerThreadStatus = ref(false);
  const serialNumber = ref('');
  const iceOptions = ref('');
  const displayName = ref('');
  const deviceSettingUrlProjector = ref('');
  const snapShot = ref(null);
  const projectorStatus = ref(null);
  const taskName = ref('');

  const deviceSettingUrl = `device/${organization}/device_settings_update/`;

  async function handleChangeTask(Serial_number, taskId) {
    const { id: deviceSettingId } = devicesSettingsDict.value[Serial_number];
    const res = await updateDeviceSettings(deviceSettingId, { Task: taskId });
    if (res !== 'error') {
      toast.success(
        'We are applying these changes. This will take less than a minute.',
        { timeout: 3000 }
      );
      return true;
    } else {
      toast.error('Something wrong at the moment. Please try later!', {
        timeout: 3000
      });
      return false;
    }
  }

  async function updateDeviceSettings(deviceSettingId, payload) {
    return await httpClient.patch(
      deviceSettingUrl,
      deviceSettingId + '/',
      payload,
      false,
      false,
      false,
      '',
      ''
    );
  }

  async function getActiveDeviceSettings(
    serialNumbersArray,
    idToSerialNoMapping
  ) {
    if (!serialNumbersArray.length) return;
    const { value: deviceIdToSerialNo } = idToSerialNoMapping;
    let payload = JSON.stringify(serialNumbersArray);

    const [error, data] = await DeviceService.getSettingsOfActiveDevices(
      false,
      { devices: payload }
    );
    if (error) return;
    setDevicesSettingsDict(data, deviceIdToSerialNo);
    await getActiveDevicesStatus(payload);
  }

  function setDevicesSettingsDict(activeDevicesSetting, deviceIdToSerialNo) {
    devicesSettingsDict.value = activeDevicesSetting.reduce((res, el) => {
      res[deviceIdToSerialNo[el.Device]] = { ...el };
      return res;
    }, {});
  }

  async function getActiveDevicesStatus(serialNumbers) {
    const payload = { Serial_number: serialNumbers };
    const [error, data] = await DeviceService.getStatusOfActiveDevices(
      false,
      payload
    );
    if (error) return;
    devicesStatusDict.value = data;
  }

  function setDeviceStatusAttributes(Serial_number, device_status) {
    let obj = {
      recording: null,
      downloading: null,
      uploading: null
    };
    if (device_status.recording) {
      obj['recording'] = 'Recording for ' + device_status.task;
    }
    if (device_status.downloading) {
      obj.downloading = {};
      obj['downloading']['name'] = device_status.downloading.name;
      obj['downloading']['progress'] = device_status.downloading.progress;
    }
    if (device_status.uploading) {
      obj.uploading = {};
      obj['uploading']['name'] = device_status.uploading.name;
      obj['uploading']['progress'] = device_status.uploading.progress;
    }
    let temp = { ...devicesStatusDict.value };
    temp[Serial_number] = { ...obj };
    devicesStatusDict.value = temp;
  }

  // ---- Modal ----//
  async function handleRecording(record, task) {
    const { Serial_number } = record;
    const { record_shift: recording } = devicesSettingsDict.value[
      Serial_number
    ];

    if (!task) {
      toast.info('Please select a task first!');
      return;
    }
    const payload = getRecordingPayload(recording, task);
    const { is_shift_managed, id } = devicesSettingsDict.value[Serial_number];

    if (is_shift_managed) {
      toast.info(
        `Cannot start recording when device is being managed.
         Please disabled "Use Start/End Time" on "Devices" page first.`
      );
      return;
    }

    if (!canStartRecording(Serial_number)) return;
    updateDeviceSettingRecord(Serial_number, { record_shift: !recording });
    // UpdateDevice(Serial_number, { isLoadingRecording: true });
    await updateDeviceSettings(id, payload);
    // UpdateDevice(Serial_number, {
    //   isLoadingRecording: false,
    // });
    toast.info(
      `We will ${recording ? 'stop' : 'start'} recording in less than a minute.`
    );
  }

  function getRecordingPayload(recording, taskId) {
    return {
      Task: taskId,
      record_shift: !recording
    };
  }

  function canStartRecording(serialNumber) {
    const { isInferenceRunning, recordInference } = devicesSettingsDict.value[
      serialNumber
    ];
    if (recordInference) {
      toast.info('Cannot start recording when task is being traced!');
      isScheduling.value = false;
      return false;
    }
    if (isInferenceRunning) {
      toast.info('Cannot start recording when task is being performed!');
      isScheduling.value = false;
      return false;
    }
    return true;
  }

  //update device setting record
  function updateDeviceSettingRecord(Serial_number, newSettingsProps) {
    const temp = { ...devicesSettingsDict.value };
    if (temp[Serial_number]) {
      temp[Serial_number] = {
        ...temp[Serial_number],
        ...newSettingsProps
      };
    }
    devicesSettingsDict.value = temp;
  }

  // Schedule Recording
  async function showScheduleModal(record) {
    console.log('showScheduleModal', record);
    const { Serial_number } = record;
    // const { day_of_week_for_recording } = devicesSettingsDict.value[
    //   Serial_number
    // ];
    selectedDays.value = JSON.parse(record?.day_of_week_for_recording);
    showSchedule.value = true;
    deviceToSchedule.value = record;
    devicesSettingsDict.value[Serial_number] = record;
  }

  function getStartEndTime(time = null) {
    const [start, end] = time ? time : startEndTime.value;
    if (!start.$d && !end.$d) {
      toast.info('Schedule time is not properly selected!');
      isScheduling.value = false;
      return;
    }
    const startDate = dateHelper.getFormattedDate(start.$d);
    const endDate = dateHelper.getFormattedDate(end.$d);
    const [startTime] = new Date(start.$d).toTimeString().split(' ');
    const [endTime] = new Date(end.$d).toTimeString().split(' ');
    return [startDate + 'T' + startTime, endDate + 'T' + endTime];
  }

  function getSchedulePayload(time = null, type = null) {
    const [start, end] = getStartEndTime(time);
    const { Serial_number } = deviceToSchedule.value;
    const taskId = devicesSettingsDict.value[Serial_number].Task;
    return {
      Task: taskId,
      start_recording_time: type === 'Recording' ? start : null,
      end_recording_time: type === 'Recording' ? end : null,
      // is_shift_managed: true,
      day_of_week_for_recording: JSON.stringify(selectedDays.value),
      shift_start_time: null,
      shift_end_time: null,
      analytics_start_time: type === 'Analytics' ? start : null,
      analytics_end_time: type === 'Analytics' ? end : null
    };
  }

  async function handleSchedule(time = null, type = null) {
    console.log('time', time);
    isScheduling.value = true;
    const { Serial_number } = deviceToSchedule.value;
    const payload = getSchedulePayload(time, type);
    console.log(payload);
    const { id } = devicesSettingsDict.value[Serial_number];

    if (!canStartRecording(Serial_number)) return;

    await updateDeviceSettings(id, payload);
    showSchedule.value = false;
    isScheduling.value = false;
  }

  async function showStreamingModal(record) {
    const { Serial_number, display_name, ice_options } = record;
    // const {
    //   rtc_thread_status,
    //   image,
    //   task,
    //   is_projector_attached,
    // } = devicesStatusDict.value[Serial_number];
    showStreamModal.value = true;
    streamSignal.value = true;
    serialNumber.value = Serial_number;
    iceOptions.value = ice_options;
    displayName.value = display_name;
    rtcServerThreadStatus.value = record.Serial_number
      ? record.rtc_thread_status
      : false;
    deviceSettingUrlProjector.value = getDeviveSettingUrl(Serial_number);
    snapShot.value = record.image
      ? 'data:image/jpg;base64, ' + record.image
      : false;
    taskName.value = record.task ? record.task : '';
    projectorStatus.value = record.is_projector_attached
      ? record.is_projector_attached
      : null;
  }

  function getDeviveSettingUrl(serialNumber) {
    if (!serialNumber) return '';
    return `device/device_status/?Serial_number=${serialNumber}`;
  }

  return {
    devicesSettingsDict,
    devicesStatusDict,
    showSchedule,
    startEndTime,
    selectedDays,
    isScheduling,
    projectorStatus,
    taskName,
    snapShot,
    deviceSettingUrlProjector,
    rtcServerThreadStatus,
    serialNumber,
    iceOptions,
    displayName,
    streamSignal,
    showStreamModal,
    handleRecording,
    showScheduleModal,
    handleSchedule,
    handleChangeTask,
    getActiveDeviceSettings,
    updateDeviceSettings,
    updateDeviceSettingRecord,
    showStreamingModal,
    getDeviveSettingUrl
  };
}
