export default {
  methods: {
    handleSpace(e) {
      const el = e.target;
      const position = el.selectionStart + 1;
      this[el.name] =
        this[el.name].slice(0, position - 1) +
        ' ' +
        this[el.name].slice(position - 1, this[el.name].length);

      this.$nextTick(() => {
        el.setSelectionRange(position, position);
      });
    },

    handleDDSpace(e) {
      const el = e.target;
      const position = el.selectionStart + 1;
      el.value =
        el.value.slice(0, position - 1) +
        ' ' +
        el.value.slice(position - 1, el.value.length);

      this.$nextTick(() => {
        el.setSelectionRange(position, position);
      });
    },

    filterOption(input, option) {
      return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
