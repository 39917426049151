import { notification } from 'ant-design-vue';
import { h } from 'vue';

export const showNotification = (
  message,
  description,
  Icon,
  iconColor,
  duration = 3
) => {
  notification.open({
    message,
    description,
    duration,
    icon: () =>
      h(Icon, {
        style: `color: ${iconColor}`,
      }),
  });
};
